<template>

	<footer class="footer">

		<div class="footer-top">

			<div class="container">

					<ul class="footer-list-certificates">
						<li><img src="@/assets/images/certificates/norton.svg" alt="norton"></li>
						<li><img src="@/assets/images/certificates/pci.svg" alt="pci"></li>
						<li><img src="@/assets/images/certificates/visa.svg" alt="visa"></li>
						<li><img src="@/assets/images/certificates/mastercard.svg" alt="mastercard"></li>
					</ul>

					<div class="footer-top-desc">
						<p>
							Online credit card processing by <a href="#0" @click.prevent>Paymentstrust</a> (PCI / DSS <br> certified Internet Payment Service Provider) and <a href="#0" @click.prevent>SegPay</a>
						</p>
					</div>

				</div>

		</div>

		<div class="footer-bottom">

			<div class="container">

				<p class="text-center">
					Easydating.com 2008-2021 © All rights reserved
				</p>

			</div>

		</div>

	</footer>

</template>

<script>
export default {
	name: 'IFooter',
}
</script>