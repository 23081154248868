
export default {
	name: 'ICard',
	props: {
		cardImg: {
			type: String,
			default: null,
			required: true
		},
		cardListName: {
			type: String,
			default: null,
		},
		cardListAge: {
			type: Number,
			default: null,
		},
		cardListStatus: {
			type: Boolean,
			default: true,
		}
  },
	methods:{
	},
	data() {
    return {
			isActive: false,
    }
  },
}