<template>

	<figure class="card">

		<img :src="cardImg" :alt="cardImg" class="card-img" v-if="cardImg">
		<div v-else>image not found</div>

		<figcaption class="card-caption" v-if="cardListName || cardListAge || cardListStatus">
			
			<ul class="card-list">

				<li class="card-list-name" v-if="cardListName">
					{{ cardListName }}
				</li>

				<li v-if="cardListAge || cardListStatus">
					<span class="card-list-age" v-if="cardListAge">{{ cardListAge }} years </span> <span class="card-list-status" v-if="cardListStatus"> Online</span>
				</li>
				
			</ul>

		</figcaption>

	</figure>

</template>

<script>
import src from "../controllers/components/CardCtrl";
export default src;
</script>