<template>
  <router-view />
</template>

<script>
export default {
  watch: {
    '$route':{
      immediate: true,
      handler: (to, from) => {
        document.title = to.meta.title || 'Nice Date'
      },
    }
  },
}
</script>
